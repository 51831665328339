
import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { FormBuilder } from '@angular/forms';
import { HistoryService } from 'src/app/services/history.service';
import { History } from '../../models/config.model';
import { orderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";
import { UserAuthService } from '../../auth/services/user-auth.service';
import { SVGIcon, fileExcelIcon, filePdfIcon } from '@progress/kendo-svg-icons';
@Component({
  selector: 'app-claim-watcher-history',
  templateUrl: './claim-watcher-history.component.html',
  styleUrls: ['./claim-watcher-history.component.scss']
})
export class ClaimWatcherHistoryComponent implements OnInit {

  public opened = false;
  public openedmessage = false;
  public isTextareaVisible: boolean = false;
  public ismessageTextboxVisible: boolean = false;

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | undefined;

  public Title: string = "";
  public skip: number = 0;
  columns: any = [];
  public jsonData: string = '';
  public message: string = '';

  public historyApiData: History[] = [];
  @Input()
  public clientId: number = 0;
  public history: History[] = [];
  public Data = [];

  public state: State = {
    skip: 0,
    take: 10,
    group: [],
  };
  //public type: PagerType = "numeric";
  //public buttonCount = 5;
  //public info = true;
  public pageSizes = true;
  //public previousNext = true;
  //public position: PagerPosition = "bottom";
  public sort: SortDescriptor[] = [];

  public gridData: GridDataResult = { data: [], total: 0 };
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public filePdfIcon: SVGIcon = filePdfIcon;

  constructor(@Inject(HistoryService) private historyservice: HistoryService, private fb: FormBuilder, private UserAuthService: UserAuthService) { }

  ngOnInit(): void {


    this.loadHistory(this.clientId, this.state.skip, this.state.take);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log("dataState called");

    if (this.state.skip !== state.skip) {
      this.gridData = { data: [], total: 0 };
    }
    this.state = state;
    // let take = parseInt(localStorage.getItem("take"))
    // if(this.state.take != take){
    // localStorage.setItem("take",this.state.take.toString())
    this.loadHistory(this.clientId, this.state.skip, this.state.take);

  }
  public loadHistory(clientId: number, skip: number, pageSize: number) {
    this.historyservice.getHistory(clientId, skip, pageSize).subscribe(data => {
      this.historyApiData = data;
      this.history = this.historyApiData;
      if (this.state.filter?.filters.length) {
        this.gridData = process(data, this.state);
        this.gridData.total = this.gridData.data.length;
      }
      else {
        this.gridData.data = data;
        this.gridData.total = data[0].total;
      }
    });
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    // this.gridData.data = data;
    this.gridData = {
      data: orderBy(this.historyApiData, this.sort),
      total: this.historyApiData[0].total,
    };
    this.historyApiData = this.gridData.data;

    // this.loadHistory(this.clientId, this.skip);
  }
  // public loadHistory(clientId:number, skip:number, pageSize:number){
  //   this.historyservice.getHistory(clientId).subscribe(data=>{
  //     this.historyApiData = data?.reverse();
  //   });

  // }

  // public loadHistory(clientId:number, skip:number, pageSize:number){
  //   this.historyservice.getHistory(clientId).subscribe(data=>{
  //     this.historyApiData = data?.reverse();
  //   });

  // }

  public getJsonDataByClientId(clientId: number) {
    this.historyservice.getJsonHistoryByClientId(clientId).subscribe(data => {
      console.log(data);
      if (data) {
        this.Title = "JSON Data";
        this.opened = true;
        this.jsonData = data['jsonData'];
        this.showTextarea();
      }

    })
  }

  public open(dataItem) {
    this.Title = "JSON Data";
    this.opened = true;
    this.jsonData = dataItem.jsonData;
    this.showTextarea();
  }

  public showTextarea() {
    this.isTextareaVisible = true;
  }

  public close(status: string): void {
    this.opened = false;
    this.openedmessage = false;
  }
  public openmessage(message) {
    this.Title = "Message";
    this.openedmessage = true;
    this.message = message;
    this.showmessageTextbox();
  }

  public showmessageTextbox() {
    this.ismessageTextboxVisible = true;
  }


}
