import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { OnInit, Inject } from '@angular/core';
import { GridDataResult, AddEvent, RemoveEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { ClientConfig } from 'src/app/interfaces/client-config';
import { Trackedtables } from 'src/app/models/config.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { SVGIcon, fileExcelIcon, filePdfIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-alltrackedtables',
  templateUrl: './alltrackedtables.component.html',
  styleUrls: ['./alltrackedtables.component.scss']
})

export class AlltrackedtablesComponent implements OnInit {
  public isTextboxVisible: boolean = false;
  public newData: any = '';
  public opened = false;
  public FileName: string = "";
  public newName: string = '';

  @ViewChild('editDialog') editDialog!: DialogRef;

  public tableName: string = "";
  public Title: string = "";
  public formData = { Name: '' };
  public formValid = false;
  public isEditMode: boolean = false;
  public pageSizes: boolean = true;
  @Input()
  id: number = 0;

  public trackedTables: Trackedtables[] = [];
  public Data = [];
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public filePdfIcon: SVGIcon = filePdfIcon;

  public trackedtableForm: FormGroup = new FormGroup({
    tableName: new FormControl(),
    createdBy: new FormControl()
  })

  constructor(private dialogService: DialogService, @Inject(MasterdataService) private masterdataservice: MasterdataService, private fb: FormBuilder) {
    this.trackedtableForm = this.fb.group({
      tableName: [],
      createdBy: []
    })
    this.allData = this.allData.bind(this);

  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.trackedTables, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }


  ngOnInit(): void {
    if (this.id > 0) {
      this.isEditMode = true;
      this.getTrackedtableData();
    }
    console.log(this.isEditMode);
    console.log('data from read api:');
    this.masterdataservice.getAllTrackedTable().subscribe(data => {
      this.trackedTables = data;

      console.log(data);
    });
  }

  public getFormData(): any {
    let data: Trackedtables = new Trackedtables();
    const formValue = this.trackedtableForm.getRawValue();
    console.log(this.trackedtableForm.getRawValue());
    data.tableName = formValue.tableName;
    data.createdBy = "deepankar";
    return (data);
  }

  public openDialog(tableName: string) {
    this.tableName = tableName;
    this.trackedtableForm.patchValue({ tableName: tableName });
    this.Title = "Edit Entity";
    this.opened = true;
    console.log(this.tableName);
    this.showTextbox();
  }

  public onCancel() {
    this.editDialog.close();
  }

  public onSave() {
    this.editDialog.close();
  }

  public close(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
    this.showTextbox();
    this.Title = "Add Entity";
    this.tableName = "";
  }

  public showTextbox() {
    this.isTextboxVisible = true;
  }

  public onInput(value: string) {
    this.newData = value;
  }

  public saveData() {
    let data = this.getFormData();
    console.log(data);
    if (this.tableName) {
      this.putTrackedtableData(data);
    } else {
      this.postTrackedtableData(data);
    }
    this.getTrackedtableData();
    this.masterdataservice.getAllTrackedTable().subscribe(data => {
      this.trackedTables = data;
    });
  }

  public putTrackedtableData(data: any) {
    this.masterdataservice.putAllTrackedTable(data).subscribe((resp) => {
      this.tableName = resp.tableName;
      if (resp.isSuccess) {
        console.log("updated successfully");
        alert("updated successfully");
        this.masterdataservice.getAllTrackedTable();
      } else {
        console.log("update error");
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }

  public postTrackedtableData(data: any) {
    console.log(data);
    this.masterdataservice.postAllTrackedTable(data).
      subscribe((resp) => {
        this.tableName = resp.tableName;
        //this.createdBy = resp.createdBy;
        if (resp.isSuccess) {
          console.log("success");
          alert("added successfully");
          //this.postAllSourceSystem();
          this.getTrackedtableData();
        } else {
          console.log("error");
        }
      }, (error: any) => {
        console.log("error1");
      });
  }

  public getTrackedtableData() {
    if (this.id > 0) {
      this.masterdataservice.getAllTrackedTable().subscribe(data => {
        this.trackedTables = data;
        if (this.trackedTables) {
          this.trackedtableForm.setValue(this.trackedTables);
          console.log(this.trackedTables);

        }
        console.log(this.trackedTables)
      });
    }
  }

}
