import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../services/users.service';
import { user } from '../models/config.model';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { process } from "@progress/kendo-data-query";
import { DOCUMENT, Location } from '@angular/common';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { AuthService } from '@auth0/auth0-angular';
import { SVGIcon, fileExcelIcon, filePdfIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  public adduser: boolean = false;
  public userform: FormGroup;
  public result;
  // public data;
  public LoggedInName: string;
  public isActive: boolean = true;
  public isEditMode: boolean = false;
  @Input()
  id: number = 0;
  @Input()
  userId: string;
  public userData: user[] = [];
  public userGridView: user[] = [];
  public user: user;
  public userValidation: any = {};
  public pageSizes: boolean = true;
  public userRole: string = "";
  //public userRoleData:any = [{id:'Admin',value:'Admin'},{id:'SuperAdmin',value:'SuperAdmin'}];
  userRoleData: any = [{ id: 'Admin', value: 'Admin' }, { id: 'SuperAdmin', value: 'SuperAdmin' }];
  get f() { return this.userform.controls; }
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public filePdfIcon: SVGIcon = filePdfIcon;


  constructor(@Inject(UsersService) private usersService: UsersService, private fb: FormBuilder, private dialogService: DialogService, private _location: Location, @Inject(DOCUMENT) public document: Document, public auth: AuthService) {

    this.userform = this.fb.group({
      id: new FormControl(),
      userId: new FormControl(this.userValidation.userId, [Validators.required, this.emailValidator]),
      address: new FormControl(),
      contactNo: new FormControl(this.userValidation.contactNo, [Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"), Validators.maxLength(10)]),
      isActive: new FormControl(),
      company: new FormControl(this.userValidation.company, [Validators.required]),
      AdminUserRole: new FormControl(this.userValidation.AdminUserRole, [Validators.required]),
    })
    this.allData = this.allData.bind(this);

  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.userData, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('token') == null) {
      this.auth.logout({
        logoutParams: {
          returnTo: this.document.location.origin
        }
      });
    }
    this.userRole = sessionStorage.getItem('role').toLowerCase();
    if (this.id > 0) {
      this.isEditMode = true;
      this.getUserData(this.id);
    }
    else {
      this.usersService.GetUsersDetails().subscribe(data => {
        this.userData = data;
        console.log(this.userData);
        this.userGridView = this.userData.reverse();

      });
    }


  }
  public getFormData(): any {
    this.LoggedInName = sessionStorage.getItem('username');
    let data: user = new user();
    const formValue = this.userform.getRawValue();
    if (this.isEditMode) {
      data.id = formValue.id;
      data.company = formValue.company;
      data.isActive = formValue.isActive;
      data.userId = formValue.userId;
      data.contactNo = formValue.contactNo;
      data.address = formValue.address;
      data.role = formValue.AdminUserRole.id;
    }
    else {
      data.userId = formValue.userId;
      data.company = formValue.company;
      data.address = formValue.address;
      data.contactNo = formValue.contactNo;
      data.role = formValue.AdminUserRole.id;
    }

    if (+this.id > 0) {
      data.id = formValue.id;
      data.modifiedBy = this.LoggedInName;
    }
    else {
      data.createdBy = this.LoggedInName;
    }
    return data;
  }

  public addNewUser(): void {
    this.adduser = true

  }
  public editUsers(value: user) {
    this.id = value.id;
    this.userId = value.userId;
    this.adduser = true;
    this.isEditMode = true;
    this.getUserData(this.id)

  }
  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }
  public saveuser(): void {
    let data = this.getFormData();
    if (this.id > 0) {
      this.putUsersDetails(data);
    }
    else {
      this.postUsersDetails(data);
    }
  }

  public postUsersDetails(data: any) {
    this.usersService.postUsersDetails(data).subscribe((resp) => {
      // this.clientId = resp.id;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "User added successfully");
        console.log("User added successfully");

        // this.getOnDemandData(this.clientId);
      } else {
        this.showConfirmation("Error", "Error : " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log(error.message);

    });
  }
  public putUsersDetails(data: any) {
    this.usersService.putUsersDetails(data).subscribe((resp) => {
      // this.name = resp.name;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "User updated successfully");
        this.usersService.GetUsersDetails();
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }
  public getUserData(id: number) {
    if (id > 0) {
      this.usersService.GetUsersDetailsById(id).subscribe(data => {
        this.user = data;
        if (this.user) {

          let index = this.userRoleData.findIndex(x => x.id == this.user.role);
          //console.log(userSelectedRole);
          // this.userform.setValue(this.user);
          this.userform.patchValue({
            id: this.user.id,
            userId: this.user.userId,
            address: this.user.address,
            company: this.user.company,
            contactNo: this.user.contactNo,
            isActive: this.user.isActive,
            AdminUserRole: this.userRoleData[index]
          });


        }
      });
    }
  }

  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
        this.adduser = false;

        window.location.reload();

      } else {
        console.log("action", result);
        window.location.reload();

      }
      this.result = JSON.stringify(result);
    });
  }
  public exit() {
    if (this.userform.dirty) {
      this.showConfirmationOnBack("You have unsaved data on the page.Do you want to continue?");
    } else {
      window.location.reload();
    }
  }


  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;

    this.userGridView = process(this.userData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "userId",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "company",
            operator: "eq",
            value: inputValue,
          },
          {
            field: "address",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "role",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "contactNo",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "createdBy",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "lastLoginDate",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "createdDate",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "modifiedDate",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "modifiedBy",
            operator: "contains",
            value: inputValue,
          }

        ],
      },
    }).data;
  }

  public showConfirmationOnBack(dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Please confirm",
      content: dialogContent,
      actions: [{ text: "Yes" }, { text: "No", themeColor: "primary" }],
      width: 350,
      height: 170,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        if (result.text == "Yes") {
          //this._location.back();
          window.location.reload();
        }
      }
      this.result = JSON.stringify(result);
    });
  }

  disabled() {
    if (this.isEditMode) {
      console.log(this.userform.dirty);
      if (!this.userform.valid || !this.userform.dirty) {
        return true;
      }
    } else {
      if (!this.userform.valid) {
        return true;
      }
    }
    return false;
  }

}
