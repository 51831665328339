<div class="card-box">
  <form class="k-form" [formGroup]="clientForm">
    <div [style.height.px]="50">
      <kendo-button _ngcontent-whw-c5 style="color: #fff; background-color: #193971 !important" (click)="open()">Add
        Client</kendo-button>
    </div>
    <div class="row">
      <kendo-grid [reorderable]="true" [kendoGridBinding]="client" filterable="menu" [resizable]="true"
        cssClass="grid-height" [pageSize]="10" [sortable]="true" [groupable]="true" [pageable]="{
          pageSizes:pageSizes
        }" style="color: #fff">
        <ng-template kendoGridToolbarTemplate>
          <kendo-grid-spacer></kendo-grid-spacer>
          <button kendoGridExcelCommand type="button" [svgIcon]="fileExcelIcon" title="Download Excel"></button>
          <button kendoGridPDFCommand type="button" [svgIcon]="filePdfIcon" title="Download Pdf"></button>
        </ng-template>

        <kendo-grid-column field="id" textField="name" valueField="id" [width]="60" filter="numeric" title="Id"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>
        <kendo-grid-column field="name" title="Client Name" [width]="120"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>
        <kendo-grid-column field="isActive" title="Is Active" filter="boolean" [width]="100"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>
        <kendo-grid-column field="Action" [width]="100" [filterable]="false" [headerClass]="'headerCustomClass'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton (click)="
                openDialog(dataItem.name, dataItem.id, dataItem.isActive)
              " style="color: #fff; background-color: #193971 !important">
              Edit
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-pdf [fileName]="'Clients.pdf'" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
          [landscape]="true" style="float: right">
          <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
          <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
              <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
            </div>
          </ng-template>
        </kendo-grid-pdf>
        <kendo-grid-excel fileName="Clients.xlsx" [fetchData]="allData"></kendo-grid-excel>
      </kendo-grid>

      <div class="add-btn">
        <ng-container *ngIf="isTextboxVisible">
          <kendo-formfield>
            <kendo-dialog [title]="Title" *ngIf="opened" (close)="close()" [minWidth]="250" [width]="450">
              <kendo-formfield>
                <kendo-floatinglabel text="Client Name">
                  <kendo-textbox *ngIf="isTextboxVisible" required [maxlength]="100"
                    formControlName="name"></kendo-textbox>
                </kendo-floatinglabel>
                <kendo-formerror *ngIf="getFormControl['name']?.errors?.['required']">Client Name
                  required</kendo-formerror>
              </kendo-formfield>
              <kendo-label *ngIf="isEditMode" class="k-checkbox-label" [for]="isActive"><input
                  [formControlName]="'isActive'" type="checkbox" #isActive kendoCheckBox />
                Is Active
              </kendo-label>
              <br />
              <kendo-button _ngcontent-whw-c5 style="color: #fff; background-color: #193971 !important"
                (click)="saveData()" [disabled]="!clientForm.valid" [disabled]="!clientForm.dirty">Save</kendo-button>
            </kendo-dialog>
          </kendo-formfield>
        </ng-container>
      </div>
    </div>
  </form>
  <div kendoDialogContainer></div>
</div>