import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { OnInit, Inject } from '@angular/core';
import { GridDataResult, AddEvent, RemoveEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { ClientConfig } from 'src/app/interfaces/client-config';
import { Sourcesystem } from 'src/app/interfaces/sourcesystem';
import { Organizations } from 'src/app/interfaces/organizations';
import { Trackedtables } from 'src/app/interfaces/trackedtables';
import { Triggertype } from 'src/app/interfaces/triggertype';
import { Timecode } from 'src/app/models/config.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { SVGIcon, fileExcelIcon, filePdfIcon } from '@progress/kendo-svg-icons';


@Component({
  selector: 'app-alltimecode',
  templateUrl: './alltimecode.component.html',
  styleUrls: ['./alltimecode.component.scss']
})
export class AlltimecodeComponent implements OnInit {
  public isTextboxVisible: boolean = false;
  public newData: any = '';
  public opened = false;
  public code: string = "";
  public Title: string = "";
  public isEditMode: boolean = false;
  public pageSizes: boolean = true;
  @Input()
  id: number = 0;
  public timeCode: Timecode[] = [];
  public Data = [];

  public timecodeForm: FormGroup;
  LoggedInName: string;
  get getFormControl() { return this.timecodeForm.controls; }
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public filePdfIcon: SVGIcon = filePdfIcon;

  constructor(private dialogService: DialogService, @Inject(MasterdataService) private masterdataservice: MasterdataService, private fb: FormBuilder) {
    this.timecodeForm = this.fb.group({
      id: new FormControl(),
      code: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9]*$")]),
      isActive: new FormControl(true),
      createdBy: new FormControl()
    })
    this.allData = this.allData.bind(this);

  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.timeCode, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }



  ngOnInit(): void {
    if (this.code == '') {
      this.isEditMode = true;
      this.getTimecodeData();
    }
    console.log(this.isEditMode);
    console.log('data from read api:');
    this.masterdataservice.getAllTimecode().subscribe(data => {
      this.timeCode = data.reverse();
      console.log(data);
    });
  }

  public getFormData(): any {
    this.LoggedInName = sessionStorage.getItem('username');
    // if(this.LoggedInName == null || this.LoggedInName == '' || this.LoggedInName == undefined){
    //   this.LoggedInName = 'pranay';
    // }
    let data: Timecode = new Timecode();
    const formValue = this.timecodeForm.getRawValue();
    console.log(this.timecodeForm.getRawValue());

    if (this.isEditMode) {
      data.code = formValue.code;
      data.id = formValue.id;
      data.isActive = formValue.isActive;
      data.modifiedBy = this.LoggedInName;
    }
    else {
      data.code = formValue.code;
      data.createdBy = this.LoggedInName;
      data.isActive = true;

    }
    return (data);
  }

  public openDialog(code: string, id: number, isActive: boolean) {
    this.isEditMode = true;
    this.code = code;
    this.id = id;
    this.timecodeForm.patchValue({ code: code });
    this.timecodeForm.patchValue({ id: id });
    this.timecodeForm.patchValue({ isActive: isActive });
    this.Title = "Edit Time code";
    this.opened = true;
    console.log(this.code);
    this.showTextbox();
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
    this.isEditMode = false;
    this.showTextbox();
    this.Title = "Add Time code";
    this.timecodeForm = this.fb.group({
      id: [],
      code: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9]*$")]),
      isActive: []

    })
  }

  public showTextbox() {
    this.isTextboxVisible = true;
  }

  public onInput(value: string) {
    this.newData = value;
  }

  public saveData() {
    let data = this.getFormData();
    console.log(data);
    if (this.code && this.isEditMode) {
      this.putTimecodeData(data);
    } else {
      this.postTimecodeData(data);
    }
    // this.getTimecodeData();
    // this.masterdataservice.getAllTimecode().subscribe(data=>{ this.timeCode = data;
    // });
  }

  public putTimecodeData(data: any) {
    this.masterdataservice.putAllTimecode(data).subscribe((resp) => {
      this.code = resp.code;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "Time code updated successfully");
        console.log("Time code updated successfully");
        // this.masterdataservice.getAllTimecode();
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }

  public postTimecodeData(data: any) {
    console.log(data);
    this.masterdataservice.postAllTimecode(data).
      subscribe((resp) => {
        // this.code = resp.code;
        //this.createdBy = resp.createdBy;
        if (resp.isSuccess) {
          this.showConfirmation("Success", "Time code added successfully");
          console.log("Time code added successfully");
          // this.getTimecodeData();
        } else {
          this.showConfirmation("Error", "Error: " + resp.message);
          console.log("Error: " + resp.message);
        }
      }, (error: any) => {
        console.log(error.message);
      });
  }

  public getTimecodeData() {
    this.masterdataservice.getAllTimecode().subscribe(data => {
      this.timeCode = data.reverse();
      if (this.timeCode) {
        this.timecodeForm.setValue(this.timeCode);
        console.log(this.timeCode);
      }
      console.log(this.timeCode)
    });

  }
  public result;
  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {

      } else {
        console.log("action", result);
        this.getTimecodeData();
        // window.location.reload();
        this.opened = false;
      }
      this.result = JSON.stringify(result);
    });
  }

}
