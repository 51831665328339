import { Component, Inject, OnInit, Input } from '@angular/core';
import { Triggertype } from 'src/app/interfaces/triggertype';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { Client } from 'src/app/interfaces/client';
import { ClientService } from 'src/app/services/client.service';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { EmailConfig } from 'src/app/models/config.model';
import { EmailConfigurationService } from 'src/app/services/email-configuration.service';
import { AllemailconfigComponent } from 'src/app/emailconfiguration/allemailconfig/allemailconfig.component';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { Location } from '@angular/common';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-emailconfiguration',
  templateUrl: './emailconfiguration.component.html',
  styleUrls: ['./emailconfiguration.component.scss']
})
export class EmailconfigurationComponent {
  public emailApiData: EmailConfig[];
  @Input()
  clientId: number = 0;
  @Input()
  triggerTypeId: number = 0;
  @Input()
  id: number = 0;
  public triggertypeSource: Triggertype[] = [];
  public triggerType: Triggertype[] = [];
  public type: Triggertype;

  public emaildata: any = {
    toEmails: '',
  };
  public isJsonNeedsToBeAttached = false;
  public clientData: Client[] = [];
  public client: Client[] = [];
  public isEditMode: boolean = false;
  public form: FormGroup;


  public emailForm: FormGroup;
  LoggedInName: string;


  get f() { return this.emailForm.controls; }

  constructor(@Inject(ClientService) private clientService: ClientService, @Inject(EmailConfigurationService) private emailConfigurationService: EmailConfigurationService, @Inject(MasterdataService) private masterdataService: MasterdataService, private fb: FormBuilder, private dialogService: DialogService, private _location: Location) {
    // this.form = new FormGroup({
    //   toEmails: new FormControl(this.emaildata.toEmails,[Validators.email,Validators.required])
    // });


    this.emailForm = this.fb.group({
      // id: new FormControl(),
      // clientId: new FormControl(),
      // triggerTypeId: new FormControl(),
      clientName: new FormControl(this.emaildata.clientName, [Validators.required]),
      triggerType: new FormControl(this.emaildata.triggerType, [Validators.required]),
      toEmails: new FormControl(this.emaildata.toEmails, [Validators.required, this.emailValidator]),
      ccEmail: new FormControl(null, [this.emailValidator]),
      bccEmail: new FormControl(null, [this.emailValidator]),
      subject: new FormControl(this.emaildata.subject, [Validators.required]),
      body: new FormControl(this.emaildata.body, [Validators.required]),
      isJsonNeedsToBeAttached: new FormControl(false),
      createdBy: new FormControl(),
      // CreatedDate: new FormControl(),
      modifiedBy: new FormControl(),
      // modifiedDate: new FormControl(),
      isActive: new FormControl(false)
    })

  }
  /*
  @author : Pranay Palaskar
  @purpose : validate Email Address when entering on input fields
  */

  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }

  public clientFilter(value: string) {
    this.clientData = this.client;
    this.clientData = this.client.filter(
      (s) => s.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public triggerTypeFilter(value: string) {
    this.triggertypeSource = this.triggerType;
    this.triggertypeSource = this.triggerType.filter(
      (s) => s.type.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  ngOnInit(): void {
    this.masterdataService.getAllClient().subscribe(data => {
      this.clientData = data.filter(s => s.isActive == true);
      this.client = data.filter(s => s.isActive == true);
      console.log(this.client);
    });

    this.masterdataService.getAllTriggerType().subscribe(data => {
      this.triggertypeSource = data;
      this.triggerType = data;
    });
    if (+this.id && this.clientId > 0) {
      this.isEditMode = true;
      this.getClientEmailData(this.clientId, this.id);
    }

  }


  public getFormData(): any {
    this.LoggedInName = sessionStorage.getItem('username');
    // if(this.LoggedInName == null || this.LoggedInName == '' || this.LoggedInName == undefined){
    //   this.LoggedInName = 'pranay';
    // }
    let data: EmailConfig = new EmailConfig();
    const formValue = this.emailForm.getRawValue();
    if (this.isEditMode) {
      data.id = formValue.id;
      data.clientId = formValue.clientId;
      data.triggerTypeId = formValue.triggerTypeId;

      console.log(data.triggerTypeId)
    }
    else {
      data.clientId = formValue.clientName == null ? Number(localStorage.getItem('clientId')) : formValue.clientName?.id;
      data.triggerTypeId = formValue.triggerType?.id;
      // data.isActive = formValue.isActive;
    }
    data.toEmails = formValue.toEmails;
    data.ccEmail = formValue.ccEmail;
    data.bccEmail = formValue.bccEmail;
    data.subject = formValue.subject;
    data.body = formValue.body;
    data.isActive = formValue.isActive;
    data.isJsonNeedsToBeAttached = formValue.isJsonNeedsToBeAttached;
    if (+this.id > 0) {
      data.id = formValue.id;
      data.clientId = this.clientId;
      data.modifiedBy = this.LoggedInName;
    }
    else {
      data.createdBy = this.LoggedInName;
    }
    return data;
  }

  public saveEmail() {
    let data = this.getFormData();
    this.emailForm.markAllAsTouched();
    console.log(data)
    if (+this.id > 0) {
      this.putClientEmailData(data);
    } else {
      this.postClientEmailData(data);
    }
  }

  public putClientEmailData(data: any) {
    this.emailConfigurationService.putClientEmailTemplate(data).subscribe((resp) => {
      this.id = resp.id;
      this.triggerTypeId = resp.triggerTypeId;

      if (resp.isSuccess) {
        this.showConfirmation("Success", "Client email configuration updated successfully");
        console.log("Client email configuration updated successfully");
        this.emailConfigurationService.getClientEmailTemplateDetails(this.clientId);
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");


    });
  }

  public postClientEmailData(data: any) {
    this.emailConfigurationService.postClientEmailTemplate(data).subscribe((resp) => {
      this.clientId = resp.clientId;
      this.triggerTypeId = resp.triggerTypeId;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "Client email configuration added successfully");
        console.log("Client email configuration added successfully");
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log(error.message);
    });
  }

  public getClientEmailData(clientId: number, id: number) {
    if (this.id && clientId && id > 0) {
      this.emailConfigurationService.getEmailTemplate(clientId, id).subscribe(data => {

        this.emailApiData = data.reverse();

        if (this.emailApiData) {
          this.emailApiData.find(item => item.id === id);
          console.log("data", this.emailApiData);

          this.emailForm.patchValue({
            clientId: this.emailApiData[0].clientId,
            id: this.emailApiData[0].id,
            clientName: this.emailApiData[0].clientName,
            triggerTypeId: this.emailApiData[0].triggerTypeId,
            triggerType: this.emailApiData[0].triggerType,
            toEmails: this.emailApiData[0].toEmails,
            ccEmail: this.emailApiData[0].ccEmail,
            bccEmail: this.emailApiData[0].bccEmail,
            subject: this.emailApiData[0].subject,
            body: this.emailApiData[0].body,
            isJsonNeedsToBeAttached: this.emailApiData[0].isJsonNeedsToBeAttached,
            isActive: this.emailApiData[0].isActive,

          });
          this.emailForm.setValue(this.emailApiData);
          this.emailForm.patchValue({ clientName: this.emailApiData[0].clientName })
        }
      });
    }
  }
  public exit() {
    if (this.emailForm.dirty) {
      this.showConfirmationOnBack("You have unsaved data on the page.Do you want to continue?");
    } else {
      window.location.reload();
    }
  }

  public result;
  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
        window.location.reload();

      } else {
        console.log("action", result);
        window.location.reload();

      }
      this.result = JSON.stringify(result);
    });
  }


  public showConfirmationOnBack(dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Please confirm",
      content: dialogContent,
      actions: [{ text: "Yes" }, { text: "No", themeColor: "primary" }],
      width: 350,
      height: 170,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        if (result.text == "Yes") {
          //this._location.back();
          window.location.reload();
        }
      }
      this.result = JSON.stringify(result);
    });
  }

  disabled() {
    if (this.isEditMode) {
      if (!this.emailForm.valid || !this.emailForm.dirty) {
        return true;
      }
    } else {
      if (!this.emailForm.valid) {
        return true;
      }
    }
    return false;
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,

  };

  public changeFilterOperator(operator: "contains"): void {
    this.filterSettings.operator = operator;
  }
}
