<div class="card-box">
  <form class="k-form" [formGroup]="clientForm">
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel text="Select Client Name" [labelCssStyle]="{ 'font-size': '15px' }">
            <kendo-dropdownlist [filterable]="true" textField="name" valueField="id"
              (filterChange)="clientFilter($event)" formControlName="clientName" [data]="filteredClientData"
              *ngIf="!isEditMode" [disabled]="disableClientDD" #clientName required>
              <ng-template kendoDropDownListNoDataTemplate>
                <div>
                  No data found.
                  <ng-container *ngIf="clifilter">Do you want to add new item - "{{ clifilter }}" ?</ng-container>
                  <br />
                  <button *ngIf="clifilter" kendoButton (click)="addNew()">
                    Add new item
                  </button>
                </div>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-textbox *ngIf="isEditMode" [readonly]="true" [formControlName]="'clientName'">
            </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror>Client Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel text="Select Source System">
            <kendo-dropdownlist placeholder="" [filterable]="true" textField="name" valueField="id"
              (filterChange)="sourceFilter($event)" [formControlName]="'sourceSystemId'" [data]="filteredSourceSystem"
              (selectionChange)="sourceSysSelectionChange($event)" #name required>
              <ng-template kendoDropDownListNoDataTemplate>
                <div>
                  No data found.
                  <ng-container *ngIf="sourcesysfilter">Do you want to add new item - "{{ sourcesysfilter }}"
                    ?</ng-container>
                  <br />
                  <button *ngIf="sourcesysfilter" kendoButton (click)="addNewSourceSys()">
                    Add new item
                  </button>
                </div>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-floatinglabel>
          <kendo-formerror>Source System is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4" *ngIf="selectedSourceSystem === 'CMS'">
        <kendo-formfield>
          <kendo-floatinglabel text="Select Client Organization Code">
            <!-- <kendo-dropdownlist [filterable]="true" textField="orgName" valueField="id"
              (filterChange)="orgFilter($event)" [formControlName]="'organizationId'"  [data]="filteredOrgData" #orgName
              required>
              <ng-template kendoDropDownListNoDataTemplate>
                <div>
                  No data found.
                  <ng-container *ngIf="organizationfilter"
                    >Do you want to add new item - "{{ organizationfilter }}" ?</ng-container
                  >
                  <br />
                  <button *ngIf="organizationfilter" kendoButton (click)="addNewOrg()">
                    Add new item
                  </button>
                </div>
              </ng-template>
            </kendo-dropdownlist> -->
            <kendo-multiselect [formControlName]="'organizationId'" [data]="filteredOrgData" [filterable]="true"
              textField="orgName" valueField="id" #orgName placeholder="Select" (filterChange)="orgFilter($event)"
              (valueChange)="onValueChange($event)" (removeTag)="removeTag($event)">
            </kendo-multiselect>
          </kendo-floatinglabel>
          <kendo-formerror>Organization is required</kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="col-md-3 col-sm-12 mb-4" *ngIf="selectedSourceSystem === 'CMS'">
        <kendo-floatinglabel text="Claim Processing Configuration">
          <kendo-textarea [formControlName]="'firstClaimIdToProcess'" (input)="onChange($event)" #firstClaimIdToProcess>
          </kendo-textarea>
        </kendo-floatinglabel>
        <kendo-formerror *ngIf="getFormControl['firstClaimIdToProcess']?.errors?.['pattern']">Please enter valid First
          claim id</kendo-formerror>


      </div>

      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel text="BCP Client ID">
            <kendo-textbox [formControlName]="'bcpClientId'"> </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror *ngIf="getFormControl['bcpClientId']?.errors?.['required']">BCP claim id
            required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4" style="margin-top: 35px!important;">
        <kendo-label class="k-checkbox-label">
          &nbsp;
          <input [formControlName]="'isNotificationNeededForFailedClaims'" type="checkbox" #failedClaimNotification
            kendoCheckBox />
          Is Notification Required For Unprocessed Claims
        </kendo-label>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-label class="k-checkbox-label">
          &nbsp;
          <input [formControlName]="'isActive'" type="checkbox" #isClientActive kendoCheckBox />
          Is Active
        </kendo-label>
      </div>
      <br />
      <!-- <div class="col-md-3 col-sm-12 mb-4" *ngIf="fetchprevclaim">
        <kendo-label class="k-checkbox-label" >
          &nbsp;
          <input [formControlName]="'fetchPreviousClaims'" type="checkbox"  #fetchPreviousClaims kendoCheckBox />
          Fetch Previous Claims
        </kendo-label>
      </div> -->
      <br />
    </div>
    <p *ngIf="selectedSourceSystem === 'CMS'"
      style="color:rgb(15, 15, 74); font-family:sans-serif; font-style: italic;"><strong>**Claim Processing
        Configuration</strong> -
      <!-- This field contains <strong>JSON</strong> having <strong>OrganizationCode, firstClaimId</strong> and
      <strong>fetchPreviousClaim</strong> mapping records. Multiple Records are separated by curly braces and comma in
      Array [].
      <br>
      <strong>organizationCode</strong> - Configured OrganizationCode from selected from <strong>"Select Client
        Organization Code"</strong> field.
      <br> -->
      <br>
      <strong>fetchPreviousClaim</strong> - This value must be Boolean i.e. 'true' or 'false'.
      <br>
      <strong>firstClaimId</strong>(optional) - If value is not provided, Claims/Dockets will be fetched from CMS
      having Last
      Changed
      Date <strong>UTC</strong> is greater than Job Start Time for particular organizationCode configuration from
      JSON.
      <br>
      <strong>DO NOT Delete Record</strong> as well as <strong>
        update</strong> OrganizationCode Manually from JSON
    </p>
    <!-- <p style="color:rgb(15, 15, 74); font-family:sans-serif; font-style: italic;">*Start Claim Processing from Claim ID
      is optional, If value is not provided,
      Claims/Dockets will be fetched having Last Changed Date from CMS greater than Job Start Time for this client
      configuration

    </p> -->
    <!-- <div class="row" *ngIf="isEditMode">
      <div class="col-md-3 col-sm-12 mb-4" >
        <kendo-floatinglabel
          text="Client Name"
          [labelCssStyle]="{ 'font-size': '15px' }"
        >
          <kendo-textbox [readonly]="true" [formControlName]="'ClientName'">
          </kendo-textbox>
        </kendo-floatinglabel>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-floatinglabel
          text="Select Client Organization Code"
          [labelCssStyle]="{ 'font-size': '15px' }"
        >
          <kendo-dropdownlist
            [filterable]="true"
            textField="text"
            valueField="value"
            (filterChange)="orgFilter($event)"
            [formControlName]="'OrgCodeId'"
            [data]="orgData"
          ></kendo-dropdownlist>
        </kendo-floatinglabel>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-floatinglabel
          text="Select Source System"
          [labelCssStyle]="{ 'font-size': '15px' }"
        >
          <kendo-dropdownlist
            placeholder=""
            [filterable]="true"
            textField="text"
            valueField="value"
            (filterChange)="sourceFilter($event)"
            [formControlName]="'SourceSystemId'"
            [data]="sourceData"
          ></kendo-dropdownlist>
        </kendo-floatinglabel>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-3 col-sm-12 ">
        <button kendoButton _ngcontent-whw-c5 style="color: #fff; background-color: #193971 !important"
          [routerLink]="['../ClientSetup']" (click)="exit()">Back</button>

        <button kendoButton _ngcontent-whw-c5
          style="color: #fff; background-color: #193971 !important;margin-left: 30px;" (click)="saveClient()"
          [disabled]="disabled()">Save</button>
      </div>
    </div>

  </form>
  <div kendoDialogContainer></div>
</div>
<!-- <pre>{{clientForm.value|json}}</pre> -->