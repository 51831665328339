<app-contentheader headerText="Email Configuration"></app-contentheader>
<div class="card-box" *ngIf="!showEmailTemplate">
  <div [style.height.px]="50">
    <button _ngcontent-whw-c5 kendoButton size="medium" rounded="medium"
      style="color: #fff; background-color: #193971 !important" (click)="addEmailtemplate()">
      Add New Email Template
    </button>
  </div>
  <kendo-grid [kendoGridBinding]="emailGridView" [reorderable]="true" filterable="menu" cssClass="grid-height"
    [pageSize]="10" [pageable]="{
      pageSizes: pageSizes
    }" [sortable]="true" [groupable]="true" [reorderable]="true" [resizable]="true">
    <ng-template kendoGridToolbarTemplate>
      <input [style.width.px]="500" placeholder="Search in all columns..." kendoTextBox (input)="onFilter($event)" />
      <kendo-grid-spacer></kendo-grid-spacer>
      <button kendoGridExcelCommand type="button" [svgIcon]="fileExcelIcon" title="Download Excel"></button>
      <button kendoGridPDFCommand [svgIcon]="filePdfIcon" title="Download Pdf"></button>
      <kendo-grid-column-chooser></kendo-grid-column-chooser>
    </ng-template>
    <kendo-grid-column field="id" title="Id" [width]="70"> </kendo-grid-column>
    <kendo-grid-column field="clientName" title="Client Name" [width]="170"></kendo-grid-column>
    <kendo-grid-column field="triggerType" title="Trigger Type" [width]="170">
    </kendo-grid-column>
    <kendo-grid-column field="toEmails" title="To Emails" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column field="subject" title="Subject" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="isActive" title="Is Active" filter="boolean" [width]="100"
      [headerClass]="'headerCustomClass'"></kendo-grid-column>
    <kendo-grid-column field="createdDate" title="Created Date" [width]="150">
    </kendo-grid-column>

    <kendo-grid-column field="clientId" title="Client Id" [width]="120" [hidden]="true">
    </kendo-grid-column>

    <kendo-grid-column field="ccEmail" title="Cc Email" [hidden]="true" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column field="bccEmail" title="Bcc Email" [hidden]="true" [width]="200">
    </kendo-grid-column>

    <kendo-grid-column field="body" title="Body" [hidden]="true" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="isJsonNeedsToBeAttached" title="Is Json Needed" filter="boolean" [width]="190"
      [hidden]="true">
    </kendo-grid-column>

    <kendo-grid-column field="createdBy" title="Created By" [hidden]="true" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="modifiedBy" title="Modified By" [hidden]="true" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="modifiedDate" title="Modified Date" [hidden]="true" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="Action" [filterable]="false" [width]="80">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton style="color: #fff; background-color: #193971 !important" (click)="editEmail(dataItem)">
          Edit
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-pdf fileName="Email Template.pdf" [allPages]="true" [repeatHeaders]="true"></kendo-grid-pdf>
    <kendo-grid-excel fileName="Email Template.xlsx" [fetchData]="allData"></kendo-grid-excel>
  </kendo-grid>
  <br />
</div>
<div *ngIf="showEmailTemplate">
  <app-emailconfiguration [triggerTypeId]="triggerTypeId" [id]="id" [clientId]="clientId"></app-emailconfiguration>
</div>