import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TwilioSmsClaimdetailsService } from '../../services/twilio-sms-claimdetails.service';
import { FormBuilder } from '@angular/forms';
import { UserAuthService } from '../../auth/services/user-auth.service';
import { TwilioSMSClaimDetails } from '../../models/config.model';
import { State, process } from "@progress/kendo-data-query";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SVGIcon, fileExcelIcon, filePdfIcon } from '@progress/kendo-svg-icons';



@Component({
  selector: 'app-twilio-smsclaim-details',
  templateUrl: './twilio-smsclaim-details.component.html',
  styleUrls: ['./twilio-smsclaim-details.component.scss']
})
export class TwilioSMSClaimDetailsComponent implements OnInit {
  isTextareaVisible: boolean;
  opened: boolean;
  Title: string;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  openedmessage: boolean;
  message: string = '';
  ismessageTextboxVisible: boolean;
  optedOutReadSMSMessage: string;
  twiliosmsApiData: TwilioSMSClaimDetails[] = [];
  twiliosmsdata: TwilioSMSClaimDetails[] = [];
  public pageSizes: boolean = true;
  public state: State = {
    // skip : 0,
    // take : 5,
    group: [],
  };
  twilioMessageBody: any;
  isTextareaVisibleBody: boolean;
  openedBody: boolean;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public filePdfIcon: SVGIcon = filePdfIcon;

  constructor(@Inject(TwilioSmsClaimdetailsService) private twiliosmsclaimdetailsservice: TwilioSmsClaimdetailsService, private fb: FormBuilder, private UserAuthService: UserAuthService) {
    this.allData = this.allData.bind(this);

  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.twiliosmsApiData, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }

  ngOnInit(): void {

    this.loadHistory();
    this.twiliosmsdata = this.twiliosmsApiData;

  }

  public loadHistory() {
    this.twiliosmsclaimdetailsservice.getTwilioSMSClaimDetails().subscribe(data => {
      this.twiliosmsApiData = data;
      this.twiliosmsdata = this.twiliosmsApiData.reverse();
    });

  }
  public open(optedOutReadSMSMessage) {
    this.Title = "Opted Out Read SMS Message";
    this.opened = true;
    this.optedOutReadSMSMessage = optedOutReadSMSMessage;
    this.showTextarea();
  }
  public openBody(twilioMessageBody) {
    this.Title = "Twilio Message";
    this.openedBody = true;
    this.twilioMessageBody = twilioMessageBody;
    this.showTextareaBody();
  }
  public showTextareaBody() {
    this.isTextareaVisibleBody = true;
  }
  public showTextarea() {
    this.isTextareaVisible = true;
  }

  public close(status: string): void {
    this.opened = false;
    this.openedmessage = false;
    this.openedBody = false;
  }
  public openmessage(message) {
    this.Title = "Message";
    this.openedmessage = true;
    this.message = message;
    this.showmessageTextbox();
  }

  public showmessageTextbox() {
    this.ismessageTextboxVisible = true;
  }

  // public onFilter(input: Event): void {
  //   const inputValue = (input.target as HTMLInputElement).value;

  //   this.twiliosmsdata = process(this.twiliosmsApiData, {
  //     filter: {
  //       logic: "or",
  //       filters: [
  //         {
  //           field: "id",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "phoneNumber",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "claimId",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "twilioSID",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "twilioMessageStatus",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "docketIdForOptInRequested",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         // {
  //         //   field: "isDocketAddedInCMS",
  //         //   operator: "contains",
  //         //   value: inputValue,
  //         // },
  //         {
  //           field: "docketType",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "createdBy",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "modifiedBy",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "createdDate",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "modifiedDate",
  //           operator: "contains",
  //           value: inputValue,
  //         }
  //       ],
  //     },
  //   }).data;

  //   // this.dataBinding.skip = 0;
  // }
  // public onFilter(input: Event): void {
  //   const inputValue = (input.target as HTMLInputElement).value;

  //   this.twiliosmsApiData = process(this.twiliosmsdata, {
  //     filter: {
  //       logic: "or",
  //       filters: [
  //         {
  //           field: "id",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "claimId",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "phoneNumber",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "clientId",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "twilioSID",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "twilioMessageStatus",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "docketIdForOptInRequested",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "createdDate",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "docketType",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "isDocketAddedInCMS",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "optedOutReadSMSMessage",
  //           operator: "contains",
  //           value: inputValue,
  //         },

  //         {
  //           field: "modifiedBy",
  //           operator: "contains",
  //           value: inputValue,
  //         },
  //         {
  //           field: "modifiedDate",
  //           operator: "contains",
  //           value: inputValue,
  //         }

  //       ],
  //     },
  //   }).data;
  //   // this.dataBinding?.skip = 0;
  // }

}
