<div class="card-box">
  <form class="kk-form" [formGroup]="organizationForm">
    <div [style.height.px]="50">
      <kendo-button _ngcontent-whw-c5 style="color: #fff; background-color: #193971 !important" *ngIf="!opened"
        (click)="open()">Add Organization</kendo-button>
    </div>
    <div class="row">
      <kendo-grid [reorderable]="true" [kendoGridBinding]="organization" filterable="menu" [resizable]="true"
        cssClass="grid-height" [pageSize]="10" [sortable]="true" [groupable]="true" [reorderable]="true" [pageable]="{
          pageSizes: pageSizes
        }" style="color: #fff">
        <ng-template kendoGridToolbarTemplate>
          <kendo-grid-spacer></kendo-grid-spacer>
          <button kendoGridExcelCommand type="button" [svgIcon]="fileExcelIcon" title="Download Excel"></button>
          <button kendoGridPDFCommand type="button" [svgIcon]="filePdfIcon" title="Download Pdf"></button>
        </ng-template>

        <kendo-grid-column field="id" title="Id" [width]="60" filter="numeric"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>
        <kendo-grid-column field="orgName" title="Organization Name" [width]="120"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>
        <kendo-grid-column field="orgCode" title="Organization Code" [width]="120"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>

        <kendo-grid-column field="isActive" filter="boolean" title="Is Active" [width]="100"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>
        <kendo-grid-column field="Action" [width]="100" [filterable]="false" [headerClass]="'headerCustomClass'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton (click)="
                openDialog(
                  dataItem.orgName,
                  dataItem.orgCode,
                  dataItem.id,
                  dataItem.isActive
                )
              " style="color: #fff; background-color: #193971 !important">
              Edit
            </button>
          </ng-template>
        </kendo-grid-column>
        <!-- <kendo-dialog #editDialog
        [title]="Title"
        >
         <kendo-textBox [(ngModel)]="Name"></kendo-textBox>
          <kendo-dialog-actions>
            <button kendoButton (click)="onCancel()">Cancel</button>
            <button kendoButton [disabled]="!formValid" (click)="onSave()">Save</button>
          </kendo-dialog-actions>
        </kendo-dialog> -->

        <kendo-grid-pdf [fileName]="'Oranization.pdf'" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
          [landscape]="true" style="float: right">
          <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
          <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
              <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
            </div>
          </ng-template>
        </kendo-grid-pdf>
        <kendo-grid-excel [fileName]="'Oranization.xlsx'" [fetchData]="allData"></kendo-grid-excel>
      </kendo-grid>

      <br />
      <br />

      <div class="Textbox">
        <ng-container *ngIf="isTextboxVisible">
          <kendo-formfield>
            <kendo-dialog [title]="Title" *ngIf="opened" (close)="close()" [minWidth]="250" [width]="450">
              <kendo-formfield>
                <kendo-floatinglabel text="Organization Name">
                  <kendo-textbox *ngIf="isTextboxVisible" #Textbox required (input)="onInput(Textbox.value)"
                    [maxlength]="100" formControlName="orgName"></kendo-textbox>
                </kendo-floatinglabel>
                <kendo-formerror *ngIf="getFormControl['orgName']?.errors?.['required']">Organization Name
                  required</kendo-formerror>
              </kendo-formfield>
              <kendo-formfield>
                <kendo-floatinglabel text="Organization Code">
                  <kendo-textbox *ngIf="isTextboxVisible" [maxlength]="10" #Textbox formControlName="orgCode"
                    required></kendo-textbox>
                </kendo-floatinglabel>

                <kendo-formerror *ngIf="getFormControl['orgCode']?.errors?.['pattern']">Only Alphabets and numerics are
                  allowed</kendo-formerror>

                <kendo-formerror *ngIf="getFormControl['orgCode']?.errors?.['required']">Organization Code
                  required</kendo-formerror>
              </kendo-formfield>
              <kendo-label *ngIf="isEditMode" class="k-checkbox-label" [for]="isActive"><input
                  [formControlName]="'isActive'" type="checkbox" #isActive kendoCheckBox />
                Is Active
              </kendo-label>
              <br>
              <br />
              <kendo-button _ngcontent-whw-c5 style="color: #fff; background-color: #193971 !important"
                (click)="saveData()" [disabled]="!organizationForm.valid"
                [disabled]="!organizationForm.dirty">Save</kendo-button>
            </kendo-dialog>
          </kendo-formfield>
        </ng-container>
      </div>
    </div>
  </form>
  <div kendoDialogContainer></div>
</div>