import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ClientConfig } from 'src/app/models/config.model';
import { ClientService } from 'src/app/services/client.service';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { Sourcesystem } from 'src/app/interfaces/sourcesystem';
import { Organizations } from 'src/app/interfaces/organizations';
import { Client } from 'src/app/interfaces/client';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { JobSetupService } from 'src/app/services/job-setup.service';
import { JobSetup } from 'src/app/models/config.model';
import { TriggerEventService } from 'src/app/services/trigger-event.service';
import { TriggerEventConfigDetails } from 'src/app/models/config.model';

import { Location } from '@angular/common';
import { delay, elementAt, takeWhile } from 'rxjs/operators';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/utils';

import { Observable } from 'rxjs';
import { NONE_TYPE } from '@angular/compiler';
import { json } from 'stream/consumers';

// import * as objectHash from 'object-hash/index';

@Component({
  selector: 'app-client-config',
  templateUrl: './client-config.component.html',
  styleUrls: ['./client-config.component.scss'],
})
export class ClientConfigComponent {
  public clientApiData: ClientConfig;
  public jobSetupApiData: JobSetup;
  public triggerEventApiData: TriggerEventConfigDetails;

  @Input()
  clientConfigurationId: number = 0;
  public selectedClientValue: Number = 0;
  public selectedOrgValue: Number = 0;
  public selectedSourceFilterValue: Number = 0;

  public client1: Client[] = [];
  public clientconfig: ClientConfig[] = [];

  public clientForm: FormGroup;
  LoggedInName: string;
  existingData: any;
  dataExist: boolean = false;
  jsondataArr: any;
  newclient: any;
  client_id: any;
  newSource: any;
  get getFormControl() { return this.clientForm.controls; }

  public isEditMode: boolean = false;
  @Input() sourceSystemMasterData: Sourcesystem[] = [];
  filteredSourceSystem: Sourcesystem[] = [];


  @Input() clientMasterData: any;
  filteredClientData: any;

  @Input() orgMasterData: Organizations[] = [];
  filteredOrgData: Organizations[] = [];

  public clientValidator: any = {};
  // isSaveButtonDisabled: boolean = true;
  // isFormDirty : boolean = false;
  allowCustom = true;
  public selectedClient: string;
  public clifilter: string;
  public sourcesysfilter: string;
  public organizationfilter: string;
  public orgIdStr: string = "";
  public orgUpdate: string = "";
  public selectedorganization: any[] = [];
  firstclaimDisable: boolean = false;
  fetchprevclaim: boolean = true;
  disableClientDD: boolean = false;
  putDone: boolean = false;
  public jsondata = []
  selectedSourceSystem = '';
  newSourceSystemAdded: boolean = false;
  newClientAdded: boolean = false;
  sourceSystem_Id: any;
  selectedOrgData = [];
  sourceAddedSuccess: boolean = false;
  clientAddedSuccess: boolean = false;
  public ClientFormDirty = false;
  // public lastItemValue: number;

  constructor(@Inject(ClientService) private clientService: ClientService, @Inject(JobSetupService) private jobSetupService: JobSetupService, @Inject(TriggerEventService) private triggerEventService: TriggerEventService, @Inject(MasterdataService) private masterdataService: MasterdataService, private fb: FormBuilder, private dialogService: DialogService, private _location: Location) {


    this.clientForm = this.fb.group({
      clientConfigurationId: new FormControl(),
      clientId: new FormControl(),
      clientName: new FormControl(this.clientValidator.clientName, [Validators.required]),
      createdBy: new FormControl(),
      firstClaimIdToProcess: new FormControl(null),
      isActive: new FormControl(true),
      isNotificationNeededForFailedClaims: new FormControl(true),
      bcpClientId: new FormControl(this.clientValidator.bcpClientId, [Validators.required]),
      lastProcessedClaimId: new FormControl(),
      fetchPreviousClaims: new FormControl(false),
      lastProcessedTriggerObjectId: new FormControl(),
      //organizationId: new FormControl([this.clientValidator.organizationId], [Validators.required]),
      organizationId: new FormControl(null),
      sourceSystemId: new FormControl(this.clientValidator.sourceSystemId, [Validators.required])
    })
    this.clientForm.get('sourceSystemId').valueChanges
      .subscribe(value => {
        if (value.name == 'CMS') {
          this.clientForm.get('organizationId').setValidators(Validators.required)
        } else {
          this.clientForm.get('organizationId').clearValidators();
        }
      }
      )

  }

  ngOnInit(): void {
    // this.isSaveButtonDisabled = true;

    this.filteredSourceSystem = this.sourceSystemMasterData;
    this.filteredClientData = this.clientMasterData;
    this.filteredOrgData = this.orgMasterData;
    this.existingData = JSON.parse(localStorage.getItem("stringifiedObj"));
    console.log(this.existingData.length)
    if (this.clientConfigurationId == 0) {
      this.clientConfigurationId = parseInt(localStorage.getItem("clientConfigurationId"));
    }
    if (this.clientConfigurationId > 0) {
      this.isEditMode = true;
      this.getClientData(this.clientConfigurationId);
    }
    // this.clientForm.valueChanges.subscribe(() => {
    //   this.isFormDirty = this.clientForm.dirty;
    //   this.isSaveButtonDisabled = !this.isFormDirty;
    // });
    //  this.lastItemValue = this.filteredClientData[this.filteredClientData.length - 1].id;



  }
  emailConditionallyRequiredValidator(formGroup: FormGroup) {
    if (formGroup.value.sourceSystemId) {
      return Validators.required(formGroup.get('organizationId')) ? {
        myEmailFieldConditionallyRequired: true,
      } : null;
    }
    return null;
  }

  public addNew(): any {
    this.newclient = {
      name: this.clifilter,
      id: 0,
      isActive: true,
      createdBy: this.LoggedInName = sessionStorage.getItem('username')
    }
    this.clientMasterData.push(this.newclient);
    console.log(this.filteredClientData)
    // this.newclient = this.filteredClientData; 
    this.clientFilter(this.clifilter);
    this.newClientAdded = true;




    //  let data: Client = {
    // id : 0,
    // name : this.clifilter,
    // isActive : true,
    // createdBy : this.LoggedInName = sessionStorage.getItem('username')
    // }
    // this.postClientData1(data)
  }
  public addNewOrg(): void {
    this.orgFilter(this.organizationfilter);
    let data: Organizations = {
      id: 0,
      orgName: this.organizationfilter,
      orgCode: this.organizationfilter,
      isActive: true,
      createdBy: this.LoggedInName = sessionStorage.getItem('username')
    }
    this.postOrganizationData(data)

  }
  public addNewSourceSys(): void {
    // this.sourceFilter(this.sourcesysfilter);
    this.newSource = {
      id: 0,
      name: this.sourcesysfilter,
      isActive: true,
      createdBy: this.LoggedInName = sessionStorage.getItem('username')
    }
    this.sourceSystemMasterData.push(this.newSource);
    this.sourceFilter(this.sourcesysfilter);
    this.newSourceSystemAdded = true;


    // this.postSourcesystemData(data);

  }




  public clientFilter(value: string) {
    // this.clientMasterData[0] = {id:0, name:"Add New Client",isActive:true,createdBy:''}
    this.clifilter = value;

    this.filteredClientData = this.clientMasterData;
    this.filteredClientData = this.clientMasterData.filter(
      (s) => s.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public orgFilter(value: string) {
    this.organizationfilter = value;
    this.filteredOrgData = this.orgMasterData;
    this.filteredOrgData = this.orgMasterData.filter(
      (s) => s.orgName.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public sourceFilter(value: string) {
    this.sourcesysfilter = value;
    this.filteredSourceSystem = this.sourceSystemMasterData;
    this.filteredSourceSystem = this.sourceSystemMasterData.filter(
      (s) => s.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }
  public sourceSysSelectionChange(value: any): void {
    this.selectedSourceSystem = value.name;

    console.log("selectedSS", this.selectedSourceSystem)
  }
  onSelected(event: any): void {
    this.selectedSourceSystem = event.target.value;;
    console.log("selectedSS", this.selectedSourceSystem)

  }
  public onClientChange(value) {
    this.selectedClient = value;
  }


  public getFormData(): any {
    let data: ClientConfig = new ClientConfig();
    this.LoggedInName = sessionStorage.getItem('username');

    const formValue = this.clientForm.getRawValue();
    if (this.isEditMode) {
      data.id = Number(localStorage.getItem('clientConfigurationId'));
      data.clientId = Number(localStorage.getItem('clientId'));
      data.clientName = formValue.clientName;
      // data.organizationId = this.orgUpdate;


    }
    else {
      if (this.newClientAdded == true) {
        data.clientId = this.client_id;
      } else {
        data.clientId = formValue.clientName.id;
      }
      data.clientName = formValue.clientName.name;
      localStorage.setItem('clientName', data.clientName);

    }
    data.organizationId = this.orgIdStr;
    if (this.newSourceSystemAdded == true) {
      data.sourceSystemId = this.sourceSystem_Id;
    }
    else {
      //data.organizationId = +formValue.organizationId?.id ? +formValue.organizationId.id : 0;
      data.sourceSystemId = +formValue.sourceSystemId?.id ? +formValue.sourceSystemId.id : 0;
    }// data.sourceSystemId = this.sourceSystem_Id;

    data.isActive = formValue.isActive;
    data.fetchPreviousClaims = formValue.fetchPreviousClaims;
    data.firstClaimIdToProcess = formValue.firstClaimIdToProcess;
    data.lastProcessedClaimId = +formValue.lastProcessedClaimId;
    data.lastProcessedTriggerObjectId = +formValue.lastProcessedTriggerObjectId;
    data.isNotificationNeededForFailedClaims = formValue.isNotificationNeededForFailedClaims;
    data.bcpClientId = formValue.bcpClientId
    if (+this.isEditMode > 0) {
      data.modifiedBy = this.LoggedInName;
      // data.modifiedBy = localStorage.getItem(this.storageKeys.createdBy);
    }
    else {
      data.createdBy = this.LoggedInName;
      //data.createdBy = localStorage.getItem(this.storageKeys.createdBy);
    }

    let sourceSystemName = this.sourceSystemMasterData.find(item => item.id === data.sourceSystemId).name;
    //console.log("source", this.source);
    //console.log(data.sourceSystemId);
    //console.log("sourceSystem", sourceSystemName);
    localStorage.setItem('clientId', data.clientId?.toString());
    localStorage.setItem('isActive', data.isActive.toString());
    localStorage.setItem('clientName', data.clientName);
    localStorage.setItem('sourceSystem', sourceSystemName);
    localStorage.setItem('orgCode', data.organizationId.toString());
    // localStorage.setItem('firstClaimIdToProcess',data.firstClaimIdToProcess.toString());
    return data;
  }

  public saveClient() {
    let data1 = this.newclient;
    // for(let i = 0; i<this.filteredClientData.length; i++){
    if (this.newClientAdded == true) {
      this.postClientData1(data1)
    }

    let data2 = this.newSource;
    if (this.newSourceSystemAdded == true) {
      this.postSourcesystemData(data2)
    }

    if (this.newClientAdded == false && this.newSourceSystemAdded == false) {
      this.saveClientConfig();
    }
    else if (this.newClientAdded == false && this.newSourceSystemAdded == true) {
      if (this.sourceAddedSuccess == true) {
        this.saveClientConfig();
      }
      else {
        this.delay(2000).then(any => {
          this.saveClientConfig();
        });

      }
    }
    else if (this.newClientAdded == true && this.newSourceSystemAdded == false) {
      if (this.clientAddedSuccess == true) {
        this.saveClientConfig();
      }
      else {
        this.delay(2000).then(any => {
          this.saveClientConfig();
        });

      }
    }
    else if (this.clientAddedSuccess == true && this.sourceAddedSuccess == true) {
      this.saveClientConfig();
    }
    else {
      this.delay(5000).then(any => {
        this.saveClientConfig();
      });

    }
    this.disabled();
  }
  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  // public saveDisable(): any{
  //   if(!this.clientForm.dirty){
  //      this.isSaveButtonDisabled = true;
  //     }
  //     else{
  //       this.isSaveButtonDisabled = false;
  //     }
  // }

  public saveClientConfig() {

    let data = this.getFormData();

    if (this.clientConfigurationId > 0) {
      this.putClientData(data);
      this.putDone = true;
      // this.updateRelatedData(data);
    }
    else if (data) {
      for (let i = 0; i < this.existingData.length; i++) {
        // this.existingData[i].clientId  == data.clientId;
        if (this.existingData[i].clientId == data.clientId) {
          this.dataExist = true;
          console.log("this is it");
          this.showConfirmation("Error", "Client configuration already created for this client");
          break;
        }
        else {
          this.dataExist = false;
        }
      }
    }
    if (this.dataExist == false && this.putDone == false) {
      // data.clientId = this.client_id

      this.postClientData(data);
    }
    this.clientForm.markAsPristine();
    this.ClientFormDirty = true;
  }

  public exit() {
    if (this.clientForm.dirty && this.isEditMode) {
      this.showConfirmationOnBack("Please Confirm", "You have unsaved data on the page.Do you want to continue?");
    } else {
      window.location.reload();
    }
  }

  public updateRelatedData(data: any) {
    this.jobSetupService.GetClientJobSetUpByClientConfigurationId(this.clientConfigurationId).subscribe(jobSetupData => {
      jobSetupData.isActive = data.isActive;
      jobSetupData.id = jobSetupData.clientJobSetupId;

      if (jobSetupData.id > 0) {
        this.jobSetupService.putClientJobSetup(jobSetupData).subscribe((resp) => {
          if (resp.isSuccess) {
            console.log("Client job setup updated successfully");
          }
          else {
            console.log("Error: " + resp.message);
          }
        })
      }
    })
    this.triggerEventService.getClientTriggerEventConfigurationDetails(this.clientConfigurationId).subscribe(triggerEventData => {
      triggerEventData.isActive = data.isActive;
      triggerEventData.id = triggerEventData.clientTriggerEventConfigurationId;

      if (triggerEventData.id > 0) {
        this.triggerEventService.updateClientTriggerEventConfiguration(triggerEventData).subscribe((resp) => {
          if (resp.isSuccess) {
            console.log("Trigger event updated successfully");
          }
          else {
            console.log("Error: " + resp.message);
          }
        })
      }
    })
  }

  public putClientData(data: any) {
    this.clientService.putClient(data).subscribe((resp) => {
      if (resp.isSuccess) {
        this.showConfirmation("Success", "Client configuration updated successfully");
        console.log("Client configuration updated successfully");
        this.getClientData(this.clientConfigurationId);
        this.disabled()
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }

  public postClientData(data: any) {

    this.clientService.postClient(data).subscribe((resp) => {
      if (resp.isSuccess) {
        this.clientConfigurationId = resp.id;
        this.showConfirmation("Success", "Client configuration added successfully");
        console.log("Client configuration added successfully");
        this.getClientData(this.clientConfigurationId);
        this.isEditMode = true;
        this.disabled()

      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log(error.message);
    });
  }
  public postClientData1(data1: any) {
    console.log(data1);
    this.masterdataService.postAllClient(data1).
      subscribe((resp) => {
        // this.name = resp.name;
        if (resp.isSuccess) {
          // this.showConfirmation("Success", "Client added successfully");

          // this.getClientData();
          this.masterdataService.getAllClient();
          this.client_id = resp.id;

          this.clientAddedSuccess = true;


        } else {
          this.showConfirmation("Error", "Error: " + resp.message);

        }
      }, (error: any) => {
        console.log("error1");
      });
    this.masterdataService.getAllClient().subscribe(data => {
      this.clientMasterData = data;
    });
  }
  public postOrganizationData(data: any) {
    console.log(data);
    this.masterdataService.postAllOrganization(data).
      subscribe((resp) => {
        // this.orgName = resp.orgName;
        // this.orgCode = resp.orgCode;
        //this.createdBy = resp.createdBy;
        if (resp.isSuccess) {
          this.showConfirmation("Success", "Organization added successfully");
          console.log("Organization added successfully");
          // this.getOrganizationData();
        } else {
          this.showConfirmation("Error", "Error: " + resp.message);
          console.log("Error: " + resp.message);
        }
      }, (error: any) => {
        console.log("error1");
      });
  }
  public postSourcesystemData(data: any) {
    console.log(data);
    this.masterdataService.postAllSourceSystem(data).
      subscribe((resp) => {
        //this.name = resp.name;
        // this.createdBy = resp.createdBy;
        if (resp.isSuccess) {
          // this.showConfirmation("Success", "Source system added successfully");
          console.log("Source system added successfully");
          //this.postAllSourceSystem();
          this.masterdataService.getAllSourceSystem();
          this.sourceSystem_Id = resp.id;

          this.sourceAddedSuccess = true;

        } else {
          this.showConfirmation("Error", "Error: " + resp.message);
          console.log("Error: " + resp.message);
        }
      }, (error: any) => {
        console.log(error.message);
      });
    this.masterdataService.getAllSourceSystem().subscribe(data => {
      this.sourceSystemMasterData = data;
    });
  }

  public getClientData(clientConfigurationId: number) {
    if (clientConfigurationId > 0) {
      this.clientService.getClientConfiguration(clientConfigurationId).subscribe(data => {
        this.clientApiData = data;
        if (this.clientApiData) {
          // let organizationData = [];

          // organizationData = (this.clientApiData.organizationDtls != null) ? this.orgMasterData?.filter(item => this.clientApiData.organizationId?.split(',').includes(item.id.toString())) : '';
          // if (this.clientApiData.organizationId) {
          //   this.selectedorganization = this.orgMasterData?.filter(item => this.clientApiData.organizationId?.split(',').includes(item.id.toString()));
          // }
          if (this.clientApiData.sourceSystemId == 1) {
            let selectedOrgApiData = JSON.parse(this.clientApiData['organizationDtls']);
            let selectedOrgId = [];
            selectedOrgApiData.forEach(element => {
              selectedOrgId.push(element.OrganizationId)
            })
            // let organizationIdArr = selectedOrgId;
            this.selectedOrgData = [];
            selectedOrgId.forEach(id => {
              let index = this.orgMasterData.findIndex((item) => item.id == id);
              if (index >= 0) {
                this.selectedOrgData.push(this.orgMasterData[index])
              }
            });

            console.log("selectedOrg", this.selectedOrgData)
            this.orgIdStr = "";
            for (let i = 0; i < this.selectedOrgData.length; i++) {
              this.orgIdStr += this.selectedOrgData[i].id + ',';

            }
            //  this.orgIdStr = selectedOrgData.join
            //  this.orgUpdate = selectedOrgData;

            //  for(let i = 0; i<selectedOrgData.length; i++){
            //   this.orgIdStr += selectedOrgData[i].organizationId + ',';

            //  }
            this.orgIdStr = this.orgIdStr.replace(/,\s*$/, "");
            console.log(this.orgIdStr);
          }
          else {
            this.selectedOrgData = null;
          }
          // console.log(organizationIdstr)
          let clientObj = { id: data.clientId, name: data.clientName }
          console.log("clientObj", clientObj)


          if (this.clientApiData.firstClaimIdToProcess == null) {
            // this.clientApiData.firstClaimIdToProcess = null;
            let orgCode = ""
            for (let i = 0; i < this.selectedOrgData.length; i++) {
              orgCode += this.selectedOrgData[i].orgCode + ',';

            }
            let jsondataToInsert = {
              organizationCode: orgCode,
              firstClaimId: "",
              fetchPreviousClaim: this.clientApiData.fetchPreviousClaims
            };
            let arr = []
            arr.push(jsondataToInsert)

            this.clientApiData.firstClaimIdToProcess = JSON.stringify(arr);
          }
          else if (((this.clientApiData.firstClaimIdToProcess.includes('{') && this.clientApiData.firstClaimIdToProcess.includes('}')) ||
            (this.clientApiData.firstClaimIdToProcess.includes('[') && this.clientApiData.firstClaimIdToProcess.includes(']')))) {
            this.jsondata = JSON.parse(this.clientApiData.firstClaimIdToProcess)
          }

          else {
            let orgCode = ""
            for (let i = 0; i < this.selectedOrgData.length; i++) {
              orgCode += this.selectedOrgData[i].orgCode + ',';

            }
            let jsondataToInsert = {
              organizationCode: orgCode,
              firstClaimId: this.clientApiData.firstClaimIdToProcess,
              fetchPreviousClaim: this.clientApiData.fetchPreviousClaims
            };
            let arr = []
            arr.push(jsondataToInsert)

            this.clientApiData.firstClaimIdToProcess = JSON.stringify(arr);
          }

          this.selectedSourceSystem = this.sourceSystemMasterData.find(item => item.id === this.clientApiData.sourceSystemId).name;

          this.clientForm.patchValue({
            // organizationId: this.orgMasterData.find(item => item.id === organizationIdstr),
            organizationId: this.selectedOrgData,
            sourceSystemId: this.sourceSystemMasterData.find(item => item.id === this.clientApiData.sourceSystemId),
            firstClaimIdToProcess: this.clientApiData.firstClaimIdToProcess,
            bcpClientId: this.clientApiData.bcpClientId,
            isActive: this.clientApiData.isActive,
            fetchPreviousClaims: this.clientApiData.fetchPreviousClaims,
            isNotificationNeededForFailedClaims: this.clientApiData.isNotificationNeededForFailedClaims,
            clientName: clientObj.name
          });
          let sourceSystemId = this.sourceSystemMasterData.find(item => item.id === this.clientApiData.sourceSystemId).name;
          localStorage.setItem('clientId', this.clientApiData.clientId?.toString());
          localStorage.setItem('clientConfigurationId', this.clientConfigurationId.toString());
          localStorage.setItem('isActive', this.clientApiData.isActive.toString());
          localStorage.setItem('clientName', clientObj.name);
          localStorage.setItem('sourceSystem', sourceSystemId);
          localStorage.setItem('orgCode', this.clientApiData.organizationId?.toString());
          //document.getElementById('disabledTabs').innerText = this.clientConfigurationId ? this.clientConfigurationId.toString():"";
        }
      });
    }
  }



  public result;
  public showConfirmation(dialogTitle, dialogContent): void {

    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,

    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        console.log("action", result);
        //  this.filteredSourceSystem = this.sourceSystemMasterData;
        this.masterdataService.getAllSourceSystem().subscribe(data => {
          this.sourceSystemMasterData = data;
        });
        this.masterdataService.getAllClient().subscribe(data => {
          this.clientMasterData = data;
        });
        this.filteredClientData = this.clientMasterData;
        this.filteredSourceSystem = this.sourceSystemMasterData;
        //  this.isEditMode = true;



      }
      this.result = JSON.stringify(result);
    });
    // if(this.clientForm.dirty)
    // this.isSaveButtonDisabled = true;

  }


  public showConfirmationOnBack(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Yes" }, { text: "No", themeColor: "primary" }],
      width: 350,
      height: 170,
      minWidth: 250

    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else if (result.text == "Yes") {
        //this._location.back();
        window.location.reload();
      }
      this.result = JSON.stringify(result);
    });
  }

  disabled() {
    if (this.clientConfigurationId > 0) {
      if (this.client_id > 0) {
        this.isEditMode = true;
      }
    }
    if (this.isEditMode) {
      if (!this.clientForm.valid || !this.clientForm.dirty) {
        return true;
      }
    } else {
      if (!this.clientForm.valid) {
        return true;
      }
    }
    return false;

    // if (this.isEditMode) {
    //   if (!this.clientForm.valid || !this.clientForm.dirty) {
    //     return true;

    //   }
    // } else {
    //   if (!this.clientForm.valid) {
    //     return true;
    //   }
    // }
    // return false;

  }

  onValueChange(event) {
    console.log(event);

    let orgIdArr = [];

    event.forEach(element => {
      orgIdArr.push(element.id);
    });

    //let orgIdStr = 
    this.orgIdStr = orgIdArr.join(',');
    if (this.orgIdStr.length > 1) {
      this.firstclaimDisable = true;
      this.fetchprevclaim = false;
    }
    else {
      this.firstclaimDisable = false;
      this.fetchprevclaim = true;

    }
    //  this.jsondata = []
    console.log("event length--> ", event.length)
    for (let i = 0; i < event.length; i++) {

      let jsondataToInsert = {
        organizationCode: event[i].orgCode,
        firstClaimId: "",
        fetchPreviousClaim: false
      }
      let flag = false
      for (let i = 0; i < this.jsondata.length; i++) {
        if (jsondataToInsert.organizationCode == this.jsondata[i].organizationCode) {
          flag = true
        }
      }
      if (flag == false) {
        this.jsondata.push(jsondataToInsert)
      }


      console.log(this.jsondata)

    }
    // this.jsondata = this
    this.clientForm.controls['firstClaimIdToProcess'].setValue(JSON.stringify(this.jsondata.length == 0 ? "no values selected" : this.jsondata));
    this.clientForm.updateValueAndValidity();



    //const selectedValues = event.value;
    //console.log(selectedValues);
  }
  public onChange(event) {
    this.jsondata = JSON.parse(event.target.value)
    console.log("this.json data after onchange--> ", this.jsondata)
    console.log("event.target.value-->", event.target.value)
  }

  public removeTag(event) {
    console.log("event.target.value--->", event.dataItem.orgCode)
    for (let i = 0; i < this.jsondata.length; i++) {
      if (event.dataItem.orgCode == this.jsondata[i].organizationCode) {
        this.jsondata.splice(i, 1)
      }

    }

    this.clientForm.controls['firstClaimIdToProcess'].setValue(JSON.stringify(this.jsondata.length == 0 ? "no values selected" : this.jsondata));
    this.clientForm.updateValueAndValidity();


  }




}
